import styled from 'styled-components';

export const StyledBloom = styled.div`
    box-sizing: border-box;
    min-height: 100vh;
    // width: 100vw;
    // margin: 0;
    // padding: 0;
    display: block;
    // overflow: hidden;

    background-color: #212121;
`;
